<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <!-- <div class="spinner mb-10"
         :class="spinnerClass || 'spinner-primary'"></div> -->
    <img src="/image/logo.png" alt="DveB Logo" />
    <div class="spin-loader mb-10">Loading...</div>
    <slot></slot>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: 'Loader',
  props: {
    spinnerClass: String,
  },
};
</script>

<style lang="scss" scoped>
.page-loader {
  .spin-loader,
  .spin-loader:before,
  .spin-loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: loaderspin 1.8s infinite ease-in-out;
    animation: loaderspin 1.8s infinite ease-in-out;
  }
  .spin-loader {
    color: #1f1f1f;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .spin-loader:before,
  .spin-loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .spin-loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .spin-loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes loaderspin {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes loaderspin {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}
</style>
